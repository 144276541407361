import React, { useEffect, useMemo, useState, useContext } from "react";
import httpclient from "../../service/httpclient";
import { useParams, withRouter } from "react-router";
import "./zhuanlans.scss";
import "../../components/lists/modules.scss";
import Pathtree from "../../components/PathTree/pathtree";
import ZhuanlanRender from "zhuanlan-render";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation } from "react-router-dom";
import Catalog from "./zhuanlan_detail_content/catalog/catalog";
import Intro from "./zhuanlan_detail_content/intro/intro";
import Comments from "./zhuanlan_detail_content/comments/comments";
import NavigationBar from "../../components/NavigationBar/navigationbar";
import SimpleScrollBar from "../../components/ScrollBar/SimpleScrollBar";
import {UserContext} from "../../App";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Zhuanlans() {
  const zhuanlansid = useParams().zhuanlansid;
  let query = useQuery();
  const selected = query.get("selected");
  const comment_id = query.get("scrollToComment");
  const default_expand_id = query.get("defaultExpand");
  const [ZhuanlansData, setZhuanlansData] = useState(null);
  const [renewMode, setRenewMode] = useState(false);
  const {userInfo, updateUserInfo} = useContext(UserContext);
  useEffect(() => {
    httpclient.getZhuanlansData(zhuanlansid, true).then((data) => {
      setRenewMode(data.time_expired);
      setZhuanlansData(data);
      if (comment_id) {
        let commentDom = document.getElementsByClassName(
          `comment-item ${comment_id}`
        )[0];
        let zhuanlanDom = document.getElementsByClassName("zhuanlans")[0];
        if (commentDom && zhuanlanDom) {
          zhuanlanDom.scrollTo(0, commentDom.getBoundingClientRect().top - 50);
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        window.scrollTo(0, 0);
      }
    });
  }, [zhuanlansid]);
  const NavigationBarData = useMemo(() => {
    if (ZhuanlansData) {
      return [
        {
          title: "课程目录",
          link: "/zhuanlans/" + zhuanlansid + "?selected=catalog",
          key: "catalog",
        },
        // {
        //   title:'课程介绍',
        //   link:'/zhuanlans/'+zhuanlansid+'?selected=intro',
        //   key:'intro',
        // },
        {
          title: "课程评论",
          link: "/zhuanlans/" + zhuanlansid + "?selected=comments",
          key: "comments",
        },
      ];
    }
  }, [zhuanlansid, ZhuanlansData]);
  return (
    <div>
      <div className="zhuanlans">
        {Pathtree("zhuanlans", ZhuanlansData, zhuanlansid)}
        <SimpleScrollBar>
          <div className="zhuanlans-wrapper">
            <div className="zhuanlans-img-wrapper">
              {!!ZhuanlansData && (
                <img
                  className="zhuanlans-img"
                  src={ZhuanlansData.zhuanlan.image}
                  alt={ZhuanlansData.zhuanlan.name}
                />
              )}
            </div>
            <div className="zhuanlans-name-wrapper">
              <div className="zhuanlans-name">
                {!!ZhuanlansData && ZhuanlansData.zhuanlan.name}
              </div>
            </div>
            <div className="zhuanlans-intro-wrapper">
              <div className="zhuanlans-intro">
                {!!ZhuanlansData && (
                  <ZhuanlanRender
                    drmVideoConfig={{
                      getVideoPlayInfo: (accessId) => {
                        return httpclient.getVideoPlayInfo(accessId);
                      },
                    }}
                    markdown={{
                      content: ZhuanlansData.zhuanlan.introduction_html,
                    }}
                  />
                )}
              </div>
              {!!(
                ZhuanlansData &&
                ZhuanlansData.zhuanlan.purchasable &&
                ZhuanlansData.locked === true
              ) && (
                <div className="purchase-wrapper">
                  {ZhuanlansData.zhuanlan.id === 36 || ZhuanlansData.zhuanlan.id === 29? (
                    <div style={{ transform: "translateX(-20px)" }}>
                      <div className="price">
                        早鸟价
                        <span style={{ color: "#5995DA" }}>
                          {ZhuanlansData.zhuanlan.price / 100}
                        </span>
                        元/年
                      </div>
                      <div className="purchase-frequency">
                        {ZhuanlansData.zhuanlan.id === 36 ? `(2024.11.30恢复原价199元/年)` : `(2024.11.30恢复原价159元/年)`}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="price">
                        RMB{ZhuanlansData.zhuanlan.price / 100}/年
                      </div>
                      <div className="purchase-frequency">
                        (已有{ZhuanlansData.zhuanlan.purchase_cnt}人购买)
                      </div>
                    </div>
                  )}

                  <NavLink
                    to={
                      "/checkout/zhuanlans/" +
                      zhuanlansid +
                      (renewMode ? "/renew" : "")
                    }
                    className="purchase-button"
                  >
                    <FontAwesomeIcon icon={faUnlock} /> 解锁专栏
                  </NavLink>
                </div>
              )}
            </div>
          </div>
          <div className="zhuanlans-detail">
            {NavigationBar(NavigationBarData, selected ? selected : "catalog")}
            <div className="zhuanlan-detail-content">
              <div
                style={
                  selected === "catalog" || !selected ? {} : { display: "none" }
                }
              >
                {Catalog(ZhuanlansData)}
              </div>
              <div style={selected === "comments" ? {} : { display: "none" }}>
                {Comments(
                  ZhuanlansData,
                  "zhuanlan",
                  zhuanlansid,
                  default_expand_id,
                    userInfo,
                    updateUserInfo
                )}
              </div>
              <div style={selected === "intro" ? {} : { display: "none" }}>
                {Intro(ZhuanlansData)}
              </div>
            </div>
          </div>
        </SimpleScrollBar>
      </div>
    </div>
  );
}

export default withRouter(Zhuanlans);
